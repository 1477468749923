import { Typography } from '@material-ui/core';
import { Button } from 'gatsby-theme-material-ui';
import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <div style={{ padding: 32 }}>
      <Helmet title="404 | Bellisima" />
      <Typography variant="h2">404</Typography>
      <Typography variant="h4">
        This is not beautiful! Page not found.
      </Typography>
      <div
        style={{
          paddingTop: 12,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Button to="/" variant="contained" color="primary">
          Go Home
        </Button>
      </div>
    </div>
  );
};
export default NotFound;
